@font-face {
  font-family: Aktiv Grotesk Corp;
  src: url("AktivGroteskCorp-LightItalic.3152d68a.eot");
  src: local(AktivGroteskCorp-LightItalic), url("AktivGroteskCorp-LightItalic.3152d68a.eot#iefix") format("embedded-opentype"), url("AktivGroteskCorp-LightItalic.048e28cc.woff") format("woff"), url("AktivGroteskCorp-LightItalic.81cd80e1.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Aktiv Grotesk Corp;
  src: url("AktivGroteskCorp-Italic.f1fffafd.eot");
  src: local(AktivGroteskCorp-Italic), url("AktivGroteskCorp-Italic.f1fffafd.eot#iefix") format("embedded-opentype"), url("AktivGroteskCorp-Italic.9bd0f9e1.woff") format("woff"), url("AktivGroteskCorp-Italic.05ebd6bc.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: Aktiv Grotesk Corp;
  src: url("AktivGroteskCorp-Medium.0efcb7db.eot");
  src: local(AktivGroteskCorp-Medium), url("AktivGroteskCorp-Medium.0efcb7db.eot#iefix") format("embedded-opentype"), url("AktivGroteskCorp-Medium.4b08eec5.woff") format("woff"), url("AktivGroteskCorp-Medium.b8a0a12f.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Aktiv Grotesk Corp;
  src: url("AktivGroteskCorp-Regular.4ac354e4.eot");
  src: local(AktivGroteskCorp-Regular), url("AktivGroteskCorp-Regular.4ac354e4.eot#iefix") format("embedded-opentype"), url("AktivGroteskCorp-Regular.245948ad.woff") format("woff"), url("AktivGroteskCorp-Regular.cfbd4bed.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Aktiv Grotesk Corp;
  src: url("AktivGroteskCorp-MediumItalic.24ae89f7.eot");
  src: local(AktivGroteskCorp-MediumItalic), url("AktivGroteskCorp-MediumItalic.24ae89f7.eot#iefix") format("embedded-opentype"), url("AktivGroteskCorp-MediumItalic.0c47a0c2.woff") format("woff"), url("AktivGroteskCorp-MediumItalic.e0547689.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Aktiv Grotesk Corp;
  src: url("AktivGroteskCorp-Bold.4cf8f19a.eot");
  src: local(AktivGroteskCorp-Bold), url("AktivGroteskCorp-Bold.4cf8f19a.eot#iefix") format("embedded-opentype"), url("AktivGroteskCorp-Bold.c012cf4a.woff") format("woff"), url("AktivGroteskCorp-Bold.e4745562.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Aktiv Grotesk Corp;
  src: url("AktivGroteskCorp-BoldItalic.6ab96ece.eot");
  src: local(AktivGroteskCorp-BoldItalic), url("AktivGroteskCorp-BoldItalic.6ab96ece.eot#iefix") format("embedded-opentype"), url("AktivGroteskCorp-BoldItalic.8cc0405f.woff") format("woff"), url("AktivGroteskCorp-BoldItalic.654c296d.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: Aktiv Grotesk Corp;
  src: url("AktivGroteskCorp-Light.96e1d201.eot");
  src: local(AktivGroteskCorp-Light), url("AktivGroteskCorp-Light.96e1d201.eot#iefix") format("embedded-opentype"), url("AktivGroteskCorp-Light.ff05cf39.woff") format("woff"), url("AktivGroteskCorp-Light.44a04c3c.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

/*# sourceMappingURL=index.54d8c5e1.css.map */
